@import "tailwindcss/base" layer(tailwindcss);
@import "tailwindcss/components" layer(tailwindcss);
@import "tailwindcss/utilities" layer(tailwindcss);
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
// @import "./app/theme/theme.scss";

html,
body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
}

* {
  --dea-navbar-h: 64px;
  --dea-navbar-mb: 48px;
  --dea-footer-h: 120px;
  --dea-footer-mt: 48px;
  --dea-main-height: calc(
    100vh - var(--dea-navbar-h) - var(--dea-navbar-mb) - var(--dea-footer-mt) -
      var(--dea-footer-h)
  );
}

main {
  min-height: var(--dea-main-height);
}

.ng-primary-color {
  color: var(--primary-color);
}

.ng-primary-background {
  background-color: var(--primary-color);
}

.p-button {
  padding: 0.5rem;
}

.no-rounded-input > * > input {
  border-radius: initial;
}

.logout-button {
  border-color: white;
}
